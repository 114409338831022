<template>
    <div class="gggHeader">
        <q-toolbar class="q-px-none" :class="$route.name === 'search' || $route.query?.location ? 'searchPageContainer' : 'q-px-container'">
            <q-toolbar-title>
                <router-link :to="{ name: 'HomeView' }">
                    <img class="image" alt="getawayGoGo logo" :src="`${awsPath}site/images/logo.webp`" />
                </router-link>
            </q-toolbar-title>

            <template v-if="$q.screen.gt.md">
                <template v-for="(menu, index) in navMenus" :key="index">
                    <q-btn
                        :aria-label="menu.label"
                        v-if="!menu.hidden"
                        flat
                        no-caps
                        :ripple="false"
                        class="btn--no-hover"
                        :class="[menu.customeClass, myDealsIds.length ? 'red' : 'black']"
                        :label="menu.label"
                        :icon="menu.icon"
                        @mouseover="(menu.menuOver = true), debounceFunc(index)"
                        @mouseout="(menu.menuOver = false), debounceFunc(index)"
                        @click="getUser.length ? clickToAction(menu.link) : clickToAction(menu.action, menu.link)">

                        <span v-if="menu.dealsCount >= 0" class="myDealsCounter">{{ myDealsIds.length }}</span>
                        <img v-if="menu.iconRight" class="menuRadarIcon" :src="`${awsPath}site/images/radar_icon.webp`" alt="GoGo Radar Icon" />

                        <q-menu v-if="menu.children" v-model="menu.show" transition-show="jump-down" transition-hide="jump-up" :offset="[-15, 5]" auto-close>
                            <q-list @mouseover="(menu.listOver = true), debounceFunc(index)" @mouseout="(menu.listOver = false), debounceFunc(index)" class="desktopSubMenu">
                                <q-item v-for="(child, item_index) in menu.children" :key="item_index" class="btn--no-hover q-py-none" active-class="menuActive" :to="child.link" clickable>
                                    <q-item-section v-if="child.userType" @click="(userType = child.userType), (showLoginModal = true)">{{ child.label }}</q-item-section>
                                    <q-item-section v-else>{{ child.label }}</q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>

                        <q-tooltip v-if="menu.tooltip" class="tooltipStyle arrow-top-left" anchor="bottom left" self="top left" :offset="[0, 5]" max-width="12rem">
                            <span v-html="menu.tooltip" />
                        </q-tooltip>
                    </q-btn>
                </template>

                <q-btn
                    class="currencyBtn btn--no-hover"
                    :ripple="false"
                    @mouseover="isCurrencyDropdownOpened = true, currencyDebounceFunc()"
                    @mouseout="isCurrencyDropdownOpened = false, currencyDebounceFunc()"
                    :content-style="{ padding: '0.5rem 0', 'z-index': 6001 }"
                    menu-anchor="bottom left"
                    menu-self="top left"
                    :menu-offset="[0, 5]"
                    :disable="currencyUpdating"
                    flat>
                    {{ currentCurrency.code }} ({{ currentCurrency.symbol }}) <q-icon :name="currencyMenu ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'" />
                    <q-menu v-model="currencyMenu" transition-show="jump-down" transition-hide="jump-up">
                        <q-list @mouseover="isCurrencyDropdownOpened = true, currencyDebounceFunc()" @mouseout="isCurrencyDropdownOpened = false, currencyDebounceFunc()">
                            <q-item v-for="(currency, i) in allCurrencyList.slice(0, 3)" :key="i" class="currencyListItem btn--no-hover" :disable="currencyUpdating" clickable v-close-popup>
                                <div @click="setCurrency(currency)">
                                    <img v-if="currentCurrency.code === currency.code" class="checkSignImg" :src="`${awsPath}site/images/tick-signup.webp`" alt="Checked Sing" />
                                    <span>{{ currency.code }} {{ currency.name }} ({{ currency.symbol }})</span>
                                </div>
                            </q-item>

                            <q-item clickable class="currencyListItem btn--no-hover" label="Show all currencies" @click="currencyListModel = true" :disable="currencyUpdating" v-close-popup>
                                <q-item-section>Show all currencies</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>

                <q-btn
                    v-if="getUser.length && userDetails.is_traveler"
                    flat
                    no-caps
                    :ripple="false"
                    :label="getUser"
                    icon="fas fa-user-circle"
                    class="profileLink btn--no-hover"
                    @mouseover="(travelerItem.menuOver = true), travelerHover()"
                    @mouseout="(travelerItem.menuOver = false), travelerHover()">
                    <q-menu v-model="travelerItem.show" transition-show="jump-down" transition-hide="jump-up" :offset="[0, 5]" auto-close>
                        <q-list @mouseover="(travelerItem.listOver = true), travelerHover()" @mouseout="(travelerItem.listOver = false), travelerHover()" class="desktopSubMenu">
                            <q-item v-for="(child, item_index) in travelerItem.children" :key="item_index" class="btn--no-hover" active-class="menuActive" :to="{ name: child.name }" clickable>
                                <q-item-section>{{ child.label }}</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>

                <q-btn
                    v-else-if="getUser.length && !userDetails.is_traveler"
                    flat
                    no-caps
                    :ripple="false"
                    :label="getUser"
                    icon="fas fa-user-circle"
                    class="profileLink btn--no-hover"
                    @mouseover="(managerItem.menuOver = true), managerHover()"
                    @mouseout="(managerItem.menuOver = false), managerHover()">
                    <q-menu v-model="managerItem.show" transition-show="jump-down" transition-hide="jump-up" :offset="[0, 5]" auto-close>
                        <q-list @mouseover="(managerItem.listOver = true), managerHover()" @mouseout="(managerItem.listOver = false), managerHover()" class="desktopSubMenu">
                            <q-item v-for="(child, item_index) in managerItem.children" :key="item_index" class="btn--no-hover" active-class="menuActive" :to="{ name: child.name }" clickable>
                                <q-item-section>{{ child.label }}</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>

                <q-btn
                    v-else
                    flat
                    no-caps
                    :ripple="false"
                    class="smallIcon btn--no-hover q-pr-none btnSize"
                    label="Login"
                    @mouseover="(loginNav.menuOver = true), openMenu()"
                    @mouseout="(loginNav.menuOver = false), openMenu()">
                    <q-menu v-if="loginNav.children" v-model="loginNav.show" transition-show="jump-down" transition-hide="jump-up" :offset="[0, 5]" auto-close>
                        <q-list @mouseover="(loginNav.listOver = true), openMenu()" @mouseout="(loginNav.listOver = false), openMenu()" class="desktopSubMenu">
                            <q-item v-for="(child, item_index) in loginNav.children" :key="item_index" class="btn--no-hover q-py-none" active-class="menuActive" :to="child.link" clickable>
                                <q-item-section @click="(userType = child.userType), (showLoginModal = true)">{{ child.label }}</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>

                <q-btn
                    v-if="!getUser.length"
                    flat
                    no-caps
                    rounded
                    :ripple="false"
                    class="signupBtn btn--no-hover q-pr-none btn"
                    @mouseover="(signUpNav.menuOver = true), openSignUpMenu()"
                    @mouseout="(signUpNav.menuOver = false), openSignUpMenu()"
                    label="Sign up">
                    <q-menu v-if="signUpNav.children" v-model="signUpNav.show" transition-show="jump-down" transition-hide="jump-up" :offset="[0, 5]" auto-close>
                        <q-list @mouseover="(signUpNav.listOver = true), openSignUpMenu()" @mouseout="(signUpNav.listOver = false), openSignUpMenu()" class="desktopSubMenu">
                            <q-item v-for="(child, item_index) in signUpNav.children" :key="item_index" class="btn--no-hover q-py-none" active-class="menuActive" :to="child.link" clickable>
                                <q-item-section @click="(userType = child.userType)">{{ child.label }}</q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>

                <q-btn v-if="getUser.length" flat no-caps :ripple="false" class="smallIcon btn--no-hover q-pr-none" label="Log Out" icon="fas fa-sign-out-alt" @click.once="userLogout" />
            </template>

            <template v-else>
                <q-btn dense unelevated icon="menu" class="mobileMenuIcon" aria-label="GoGo Menu Icon">
                    <q-menu
                        fit
                        anchor="bottom left"
                        self="top left"
                        transition-show="jump-down"
                        transition-hide="jump-up"
                        style="width: 280px"
                        max-height="510px"
                        class="mobileMenuPos"
                        aria-label="Mobile Menu">
                        <q-list class="q-pa-md" style="min-width: 100px">
                            <template v-for="(menu, index) in navMenus" :key="index">
                                <q-item
                                    v-if="!menu.hidden"
                                    class="mobileMenu btn--no-hover"
                                    :class="{ mobileSep: menu.seprater }"
                                    @click="getUser.length ? clickToAction(menu.link) : clickToAction(menu.action, menu.link)"
                                    clickable>
                                    <q-item-section v-if="menu.icon" side :top="menu.show">
                                        <q-icon :name="menu.icon" color="black" />
                                    </q-item-section>

                                    <q-item-section :class="[menu.customeClass, myDealsIds.length ? 'red' : 'black']" @click="closeMenu(menu)">
                                        <div class="mobileMenuTitle">
                                            {{ menu.label }}
                                            <span v-if="menu.dealsCount >= 0">{{ myDealsIds.length }}</span>
                                        </div>

                                        <template v-if="menu.children && menu.show">
                                            <q-list>
                                                <q-item
                                                    v-for="(child, item_index) in menu.children"
                                                    :key="item_index"
                                                    dense
                                                    :to="child.link"
                                                    active-class="menuActive"
                                                    class="btn--no-hover mobileMenuTitle"
                                                    :class="{ mobileSubSep: child.seprater }"
                                                    clickable>
                                                    <q-item-section v-if="child.userType" @click="(userType = child.userType), (showLoginModal = true)">
                                                        {{ child.label }}
                                                    </q-item-section>

                                                    <q-item-section v-else>
                                                        {{ child.label }}
                                                    </q-item-section>
                                                </q-item>
                                            </q-list>
                                        </template>
                                    </q-item-section>

                                    <q-item-section v-if="menu.iconRight" dense>
                                        <img class="menuRadarIcon" :src="`${awsPath}site/images/radar_icon.webp`" alt="GoGo Radar Icon" />
                                    </q-item-section>

                                    <q-tooltip v-if="menu.tooltip" class="bg-black text-white" anchor="bottom left" self="top left" :offset="[0, 5]" max-width="10rem">
                                        <span v-html="menu.tooltip" />
                                    </q-tooltip>
                                </q-item>
                            </template>

                            <q-item clickable class="mobileMenu btn--no-hover">
                                <q-item-section>
                                    <q-btn-dropdown
                                        dense
                                        outline
                                        :ripple="false"
                                        class="mobileCurrencyBtn"
                                        dropdown-icon="fa-solid fa-caret-down"
                                        @click="showMobileCurrencyList = !showMobileCurrencyList"
                                        :label="`${currentCurrency.code} (${currentCurrency.symbol})`" />

                                    <template v-if="showMobileCurrencyList">
                                        <q-list class="mobileCurrencyList">
                                            <q-item
                                                v-for="(item, i) in allCurrencyList.slice(0, 3)"
                                                :key="i"
                                                class="btn--no-hover currencyListItem"
                                                @click="setCurrency(item), showMobileCurrencyList = false"
                                                :disable="currencyUpdating"
                                                dense
                                                clickable>
                                                <q-item-section v-if="item.code === currentCurrency.code" class="q-pr-none" side>
                                                    <img class="checkSignImg" :src="`${awsPath}site/images/tick-signup.webp`" alt="Checked Sing" />
                                                </q-item-section>

                                                <q-item-section>{{ item.code }} {{ item.name }} ({{ item.symbol }})</q-item-section>
                                            </q-item>

                                            <q-item class="btn--no-hover currencyListItem" clickable label="Show all currencies" @click="currencyListModel = true" v-close-popup>
                                                <q-item-section>Show all currencies</q-item-section>
                                            </q-item>
                                        </q-list>
                                    </template>
                                </q-item-section>
                            </q-item>

                            <q-item v-if="getUser.length && userDetails.is_traveler" class="mobileMenu btn--no-hover" clickable>
                                <q-item-section @click="closeMenu(travelerItem)">
                                    <div class="profileItem text-center">
                                        <q-icon class="profileIcon" name="fas fa-user-circle" color="white" />
                                        <span>{{ getUser }}</span>
                                    </div>

                                    <template v-if="travelerItem.children && travelerItem.show">
                                        <q-list class="mobileSep">
                                            <q-item
                                                v-for="(child, i) in travelerItem.children"
                                                :key="i"
                                                dense
                                                :to="{ name: child.name }"
                                                active-class="menuActive"
                                                class="btn--no-hover mobileMenuTitle"
                                                clickable>
                                                <q-item-section>{{ child.label }}</q-item-section>
                                            </q-item>
                                        </q-list>
                                    </template>
                                </q-item-section>
                            </q-item>

                            <q-item v-else-if="getUser.length && !userDetails.is_traveler" class="mobileMenu btn--no-hover" clickable>
                                <q-item-section @click="closeMenu(managerItem)">
                                    <div class="profileItem text-center">
                                        <q-icon class="profileIcon" name="fas fa-user-circle" color="white" />
                                        <span>{{ getUser }}</span>
                                    </div>

                                    <template v-if="managerItem.children && managerItem.show">
                                        <q-list class="mobileSep">
                                            <q-item
                                                v-for="(child, i) in managerItem.children"
                                                :key="i"
                                                dense
                                                :to="{ name: child.name }"
                                                active-class="menuActive"
                                                class="btn--no-hover mobileMenuTitle"
                                                clickable>
                                                <q-item-section>{{ child.label }}</q-item-section>
                                            </q-item>
                                        </q-list>
                                    </template>
                                </q-item-section>
                            </q-item>

                            <q-item v-else class="loginBtn mobileMenu btn--no-hover" clickable>
                                <q-item-section @click="closeMenu(loginNav)">
                                    <div class="mobileMenuTitle">Login</div>

                                    <template v-if="loginNav.children && loginNav.show">
                                        <q-list>
                                            <q-item
                                                v-for="(child, i) in loginNav.children"
                                                :key="i"
                                                dense
                                                :to="child.link"
                                                active-class="menuActive"
                                                class="btn--no-hover mobileMenuTitle"
                                                :class="{ mobileSubSep: child.seprater }"
                                                clickable>
                                                <q-item-section @click="(userType = child.userType), (showLoginModal = true)">
                                                    {{ child.label }}
                                                </q-item-section>
                                            </q-item>
                                        </q-list>
                                    </template>
                                </q-item-section>
                            </q-item>

                            <q-item v-if="!getUser.length" class="mobileMenu btn--no-hover" clickable>
                                <q-item-section @click="closeMenu(signUpNav)">
                                    <q-btn
                                        v-if="!getUser.length"
                                        flat
                                        no-caps
                                        rounded
                                        dense
                                        :ripple="false"
                                        class="signupBtnMob btn--no-hover btn"
                                        @mouseover="(signUpNav.menuOver = true), openSignUpMenu()"
                                        @mouseout="(signUpNav.menuOver = false), openSignUpMenu()"
                                        label="Sign up" />
                                    <template v-if="signUpNav.children && signUpNav.show">
                                        <q-list>
                                            <q-item
                                                v-for="(child, i) in signUpNav.children"
                                                :key="i"
                                                dense
                                                :to="child.link"
                                                active-class="menuActive"
                                                class="btn--no-hover mobileMenuTitle"
                                                :class="{ mobileSubSep: child.seprater }"
                                                @click="closeMenu(signUpNav)"
                                                clickable>
                                                <q-item-section @click="(userType = child.userType)">
                                                    {{ child.label }}
                                                </q-item-section>
                                            </q-item>
                                        </q-list>
                                    </template>
                                </q-item-section>
                            </q-item>

                            <q-item v-if="getUser.length" class="loginBtn mobileMenu btn--no-hover" @click.once="userLogout" clickable>
                                <q-item-section side top>
                                    <q-icon name="fas fa-sign-out-alt" color="black" size=".875rem" />
                                </q-item-section>

                                <q-item-section>
                                    <div class="mobileMenuTitle">Log Out</div>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                </q-btn>
            </template>
        </q-toolbar>

        <Login
            v-if="showLoginModal"
            :prop_user_type="userType"
            :prop_show_modal="showLoginModal"
            :prop_login_redirect="loginRedirect"
            @loginModalhidden="(userType = null), (showLoginModal = false)" />

        <q-dialog v-model="currencyListModel" class="trackDealModel">
            <q-card class="currencyModel generalModelStyle trackDealsFormPopup">
                <div class="row no-wrap items-start q-pa-md">
                    <q-toolbar-title class="q-pr-none">
                        <p>Select Currency</p>
                    </q-toolbar-title>
                    <q-btn flat dense color="red-8" class="btn--no-hover" icon="close" size="1rem" v-close-popup />
                </div>

                <div class="row">
                    <div v-for="(currency, i) in allCurrencyList" :key="i" class="col-12 col-lg-3 col-md-4 col-sm-6 currencyItem">
                        <div class="currencyDetails cursor-pointer" @click="setCurrency(currency)">
                            <img v-if="currentCurrency.code === currency.code" :src="`${awsPath}site/images/tick-signup.webp`" alt="Checked Sing" />
                            <b>{{ currency.code }}</b> {{ currency.name }} ({{ currency.symbol }})
                        </div>
                    </div>
                </div>
            </q-card>
        </q-dialog>
    </div>
</template>

<style lang="scss" scoped>
img.menuRadarIcon {
    width: 1.875rem;
    position: absolute;
    top: -5px;
    right: -4px;
}

.myDealsCounter {
    padding-left: 0.25rem;
}

.profileLink {
    background: $ggg-red;
    color: #fff;
    border-radius: 0.75rem;
    min-height: 2.188rem;
    margin-left: 1.5rem;

    :deep(.q-icon) {
        margin-right: 0.5rem;
        font-size: 1rem;
    }
}

.signupBtn {
    padding: 0px 14px;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    white-space: nowrap;
    vertical-align: middle;
    :hover {
        color: #fff;
    }
}

.btnSize {
    padding: 0px 16px;
}

.loginBtn.mobileMenu {
    :deep(.q-item__section--top .q-icon:before, .q-item__section--top .q-icon:after) {
        margin-top: .25rem;
    }

    :deep(.q-item--dense) {
        padding: .125rem;
    }

}

.signupBtnMob.mobileMenu {
    :deep(.q-item__section--top .q-icon:before, .q-item__section--top .q-icon:after) {
        margin-top: .25rem;
    }

    :deep(.q-item--dense) {
        padding: .125rem;
    }
    :hover {
        color: #fff;
    }
}

.signupBtnMob {
    width: fit-content;
    padding: 0.35rem 0.5rem;
}

.q-header .profileLink.q-btn:hover {
    color: #fff;
}

:deep(.q-btn .q-focus-helper) {
    display: none;
}

:deep(.q-btn.smallIcon .q-icon) {
    font-size: 1rem;
    margin: auto 0.5rem;
}

.mobileSep,
.mobileSubSep {
    border-top: 1px solid #ccc;
}

.mobileSubSep {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
}

.currencyModel {
    max-width: 1140px;

    p {
        margin: unset;
        font-size: 1.25rem;
    }

    .currencyItem {
        padding: 1rem;

        .currencyDetails {
            min-height: 1.5rem;
            font-size: .88rem;

            img {
                vertical-align: middle;
                margin-right: 0.25rem;
                height: 1rem;
            }
        }
    }
}

.currencyListItem {
    padding: .25rem 1rem;
    width: max-content;

    &:hover {
        color: $ggg-red;
    }

    img.checkSignImg {
        vertical-align: middle;
        height: 1.125rem;
        padding-right: .5rem;
    }
}

.currencyBtn {
    height: 35px;
    min-height: 35px;
    margin-left: .5rem;
    padding: 0 .25rem 0 .75rem;
    border: 1px solid #0000003d;
    border-radius: .25rem;
}

.mobileCurrencyBtn {
    width: fit-content;
    padding: 0.35rem 0.5rem;

    &::before {
        border-color: #dbdbdb;
    }
}

.mobileCurrencyList {
    width: fit-content;
    margin-top: 0.25rem;
    padding: 0.25rem 0;
    border: 1px solid #00000026;
    border-radius: 3px;
}

@media screen and (min-width: $breakpoint-md) {
    .menuDealIcon {
        width: 7.5rem;
        margin-right: 1rem;
        padding: .25rem .375rem .375rem .75rem;
    }

    .menuDealIcon.black {
        background: url(#{$awsPath}site/images/tag-black-border.webp) no-repeat;
        background-size: 100%;
    }

    .menuDealIcon.red {
        color: #fff;
        background: url(#{$awsPath}site/images/tag_red_bg.webp) no-repeat;
        background-size: 100%;

        &:hover {
            color: #fff;
        }
    }

    .howToBtn {
        padding-left: 1.75rem;
        padding-right: 0.5rem;
    }
}

@media screen and (max-width: $breakpoint-md) {
    img.menuRadarIcon {
        left: 4.375rem;
    }

    .menuDealIcon {
        max-width: 7.5rem;
        height: 2.5rem;
        text-align: center;
        display: inline;
        padding-top: 0.45rem;
    }

    .menuDealIcon.black {
        background: url(#{$awsPath}site/images/tag-black-border.webp) no-repeat;
        background-size: 100%;
    }

    .menuDealIcon.red {
        color: #fff;
        background: url(#{$awsPath}site/images/tag_red_bg.webp) no-repeat;
        background-size: 100%;

        &:hover {
            color: #fff;
        }
    }

    .mobileMenu {
        .menuDealIcon.red .mobileMenuTitle:hover {
            color: unset;
        }

        .profileItem {
            width: fit-content;
            margin-bottom: .625rem;
            padding: 0.5rem .75rem;
            background-color: #ad2222;
            border-color: #ad2222;
            color: #fff;
            border-radius: 0.75rem;

            .profileIcon {
                padding-right: 0.5rem;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-xs) {
    .gggHeader img.image {
        max-width: 10.5rem;
    }
}
</style>

<script>
import { debounce } from 'quasar';
import _ from 'lodash';
import { useCookies } from 'vue3-cookies';
import Login from '../login.vue';
import { removeUserData } from '@/assets/js/commonFunctions';

const { cookies } = useCookies();

export default {
    name: 'GGHeader',
    components: { Login },
    data() {
        return {
            navMenus: [{
                label: 'My Deals',
                ...(cookies.isKey('user_login') ? { tooltip: 'Click here to view your<br />saved deals!' }
                    : { tooltip: 'View and save your favorite deals by creating a traveler account or logging in.' }),
                dealsCount: 0,
                customeClass: 'menuDealIcon',
                link: 'TravelerDeals',
                action: 'login',
                hidden: false,
            }, {
                label: 'GoGo Radar',
                iconRight: 'radar',
                ...(cookies.isKey('user_login') ? { tooltip: 'Click here to view the<br />deals you are tracking.' }
                    : { tooltip: 'Be the first to know about a deal you are looking for. Track a deal with GoGo Radar by creating a traveler account or logging in.' }),
                link: 'TravelerRadar',
                action: 'login',
                hidden: false,
            }, {
                label: 'How to getawayGoGo',
                customeClass: 'howToBtn',
                show: false,
                menuOver: false,
                listOver: false,
                seprater: true,
                hidden: false,
                children: [{
                    label: 'Traveler',
                    link: '/how-to-gogo-traveler',
                    seprater: true,
                }, {
                    label: 'Property Manager',
                    link: '/how-to-gogo-property-manager',
                }],
            }, {
                label: 'FAQ',
                show: false,
                menuOver: false,
                listOver: false,
                hidden: false,
                children: [{
                    label: 'Traveler',
                    link: '/how-to-gogo-traveler/frequently',
                    seprater: true,
                }, {
                    label: 'Property Manager',
                    link: '/how-to-gogo-property-manager/frequently',
                }],
            }],
            loginNav: {
                show: false,
                menuOver: false,
                listOver: false,
                children: [{
                    label: 'Traveler',
                    userType: 'traveler',
                    seprater: true,
                }, {
                    label: 'Property Manager',
                    userType: 'manager',
                }],
            },
            signUpNav: {
                show: false,
                menuOver: false,
                listOver: false,
                children: [{
                    label: 'Traveler',
                    userType: 'traveler',
                    seprater: true,
                    link: '/traveler-signup/',
                }, {
                    label: 'Property Manager',
                    userType: 'manager',
                    link: '/manager-signup/',
                }],
            },
            travelerItem: {
                show: false,
                menuOver: false,
                listOver: false,
                children: [{
                    label: 'Traveler',
                    name: 'TravelerHome',
                }, {
                    label: 'Messages',
                    name: 'TravelerInbox',
                }, {
                    label: 'My Reservation',
                    name: 'TravelerReservation',
                }, {
                    label: 'My Deals',
                    name: 'TravelerDeals',
                }, {
                    label: 'GoGo Radar',
                    name: 'TravelerRadar',
                }, {
                    label: 'Profile',
                    name: 'TravelerProfile',
                }],
            },
            managerItem: {
                show: false,
                menuOver: false,
                listOver: false,
                children: [{
                    label: 'Manager',
                    name: 'ManagerHome',
                }, {
                    label: 'Profile',
                    name: 'ManagerProfile',
                }, {
                    label: 'Messages',
                    name: 'ManagerInbox',
                }, {
                    label: 'Reservation',
                    name: 'ManagerReservation',
                }, {
                    label: 'GoGo Deals',
                    name: 'ManagerDeals',
                }, {
                    label: 'Account',
                    name: 'ManagerAccount',
                }, {
                    label: 'Help',
                    name: 'ManagerHelp',
                }],
            },
            showLoginModal: false,
            loginRedirect: null,
            userType: null,
            userDetails: _.get(this.$store, 'state.storeUserDetails.userData'),
            currencyListModel: false,
            showMobileCurrencyList: false,
            currentCurrency: _.get(this.$store, 'state.storeUserCurrency.userCurrency'),
            allCurrencyList: _.get(this.$store, 'state.storeCurrencyList.currencyList'),
            isAppBuildVersionUpdated: false,
            isCurrencyDropdownOpened: false,
            currencyMenu: false,
            currencyUpdating: false,
        };
    },
    computed: {
        getUser() {
            if (localStorage.getItem('userDetails') && cookies.isKey('user_login')) {
                const userName = `${this.userDetails.first_name} ${this.userDetails.last_name.substring(0, 1)}.`;
                return userName;
            }
            return { null: this.userDetails };
        },
        myDealsIds() {
            return _.get(this.$store, 'state.storeMyDeals.properties.length') ? _.get(this.$store, 'state.storeMyDeals.properties') : [];
        },
    },
    watch: {
        '$store.state.storeUserDetails.userData': function(newVal) {
            this.userDetails = newVal;
            if (cookies.isKey('user_login') && !newVal.is_traveler) {
                this.navMenus[0].hidden = true;
                this.navMenus[1].hidden = true;
                this.navMenus[2].seprater = false;
            } else {
                this.navMenus[0].hidden = false;
                this.navMenus[1].hidden = false;
            }
        },
        '$store.state.storeUserCurrency.userCurrency': function(newVal, oldVal) {
            if (oldVal.code !== newVal.code) this.currentCurrency = newVal;
        },
        isAppBuildVersionUpdated(newValue) {
            if (newValue) {
                this.getCurrencyList(); // Update currency list when version changes
            }
        },
    },
    methods: {
        debounceFunc: debounce(function(index) {
            this.checkMenu(index);
        }, 0),
        checkMenu(index) {
            if (this.navMenus[index].menuOver || this.navMenus[index].listOver) {
                this.navMenus.filter((value) => {
                    value.show = false;
                });
                this.navMenus[index].show = true;
            } else {
                this.navMenus[index].show = false;
            }
        },
        closeMenu(menu) {
            if (!menu.show) {
                for (let i = 0; i < this.navMenus.length; i += 1) {
                    if (this.navMenus[i].show) this.navMenus[i].show = false;
                }
            }
            menu.show = !menu.show;
        },
        openMenu: debounce(function() {
            if (this.loginNav.menuOver || this.loginNav.listOver) this.loginNav.show = true;
            else this.loginNav.show = false;
        }, 0),

        openSignUpMenu: debounce(function() {
            if (this.signUpNav.menuOver || this.signUpNav.listOver) this.signUpNav.show = true;
            else this.signUpNav.show = false;
        }, 0),

        travelerHover: debounce(function() {
            if (this.travelerItem.menuOver || this.travelerItem.listOver) this.travelerItem.show = true;
            else this.travelerItem.show = false;
        }, 0),

        managerHover: debounce(function() {
            if (this.managerItem.menuOver || this.managerItem.listOver) this.managerItem.show = true;
            else this.managerItem.show = false;
        }, 0),

        currencyDebounceFunc: debounce(function() {
            if (this.isCurrencyDropdownOpened) this.currencyMenu = true;
            else this.currencyMenu = false;
        }, 0),

        clickToAction(actionFunction, loginRef) {
            if (actionFunction === 'login') {
                this[actionFunction]();
                this.loginRedirect = loginRef;
            } else this.$router.push({ name: actionFunction });
        },
        getCurrencyList() {
            this.axios.get('/api/v2/currency').then((response) => {
                const responseData = response.data.data;
                if (responseData) {
                    this.allCurrencyList = responseData;
                    this.$store.commit('storeCurrencyList/currencyList', this.allCurrencyList);
                }
            });
        },
        setCurrency(currency) {
            this.currencyListModel = false;
            if (localStorage.getItem('userDetails') && cookies.isKey('user_login')) {
                this.currencyUpdating = true;
                this.axios.post('/api/v1/update-user-currency', {
                    user_id: this.userDetails.user_id,
                    currency_code: currency.code,
                }).then((response) => {
                    this.$store.commit('storeUserCurrency/addCurrency', currency);
                    this.$q.notify({
                        icon: 'check_circle', color: 'black', position: 'bottom', message: response.data.message,
                    });
                }).catch(() => {
                    this.$q.notify({
                        icon: 'cancel', color: 'black', position: 'bottom', message: 'Something Went Wrong, Please Try Again',
                    });
                }).then(() => {
                    this.currencyUpdating = false;
                });
            } else {
                this.$store.commit('storeUserCurrency/addCurrency', currency);
            }
        },
        login() {
            this.userType = 'traveler';
            this.showLoginModal = true;
        },
        userLogout(isRedirectOnHome = true) {
            this.axios.get('/api/logout', {
                params: { user_id: this.userDetails.user_id },
            }).then(() => {
                this.userDetails = null;
                removeUserData();
                if (isRedirectOnHome) window.location = '/';
            }).catch((error) => {
                const errorResponse = error.response;

                if ([400, 403].includes(errorResponse.status)) {
                    this.$q.notify({
                        icon: 'cancel', color: 'black', position: 'bottom', message: errorResponse.data.message,
                    });
                }
            });
        },
        updateVersionData() {
            const currentAppBuildVersion = process.env.VUE_APP_BUILD_VERSION;
            const storedAppBuildVersion = _.get(this.$store, 'state.storeAppBuildVersion.version');

            // Check if the current build version is different from the stored build version
            if (!currentAppBuildVersion || (currentAppBuildVersion && currentAppBuildVersion !== storedAppBuildVersion)) {
                // If different, update the stored build version in the Vuex store and local storage
                this.$store.commit('storeAppBuildVersion/updateBuildVersion', currentAppBuildVersion);

                // Set flag to indicate the build version was updated
                this.isAppBuildVersionUpdated = true;
            }
        },
    },
    created() {
        // Run method to update version data
        this.updateVersionData();

        if (this.allCurrencyList.length < 1) this.getCurrencyList();
    },
    mounted() {
        const isLogin = cookies.isKey('user_login');
        const isUserData = localStorage.getItem('userDetails');

        if ((!isLogin && isUserData) || (isLogin && !isUserData)) {
            this.userDetails = null;
            removeUserData();
        } else if (isLogin && !this.userDetails.is_traveler) {
            this.navMenus[0].hidden = true;
            this.navMenus[1].hidden = true;
            this.navMenus[2].seprater = false;
            this.emitter.on('userLogout', () => {
                this.userLogout(false);
            });
        }
    },
};
</script>
