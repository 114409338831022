<template>
    <div class="row justify-center items-center q-px-container features">
        <div class="contentWidth row justify-center items-center">
            <div class="col-12 col-sm">
                <router-link :to="{ name: 'TravelerOverview' }">
                    <div class="feature">
                        <img class="headerIcon" alt="icon" :src="`${awsPath}site/images/book-direct-arrows.webp`" />
                        <span>Book Direct</span>
                    </div>
                </router-link>
                <div v-if="$q.screen.gt.xs" class="smallText">Best prices and personal service directly from property managers and hosts.</div>
            </div>

            <div class="col-12 col-sm">
                <router-link :to="{ name: 'TravelerOverview' }">
                    <div class="feature">
                        <img class="headerIcon" alt="icon" :src="`${awsPath}site/images/no-icon.webp`" />
                        <span>No Booking Fee</span>
                    </div>
                </router-link>
                <div v-if="$q.screen.gt.xs" class="smallText">Save up to 20% with no booking service fees on getawayGoGo.</div>
            </div>

            <div class="col-12 col-sm">
                <router-link :to="{ name: 'TravelerOverview' }">
                    <div class="feature">
                        <img class="headerIcon" alt="icon" :src="`${awsPath}site/images/no-icon.webp`" />
                        <span>No Markups</span>
                    </div>
                </router-link>
                <div v-if="$q.screen.gt.xs" class="smallText">getawayGoGo does not increase prices from property managers.</div>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
.features {
    padding: 2rem 1.3rem;
    text-align: center;

    .contentWidth {
        gap: 1.5rem;
    }

    .feature {
        display: inline-flex;
        align-items: center;
        column-gap: .5rem;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .headerIcon {
        width: 26px;
    }

    a {
        color: #484844;
    }
}

@media only screen and (min-width: 768px) {
    .features .feature {
        padding-bottom: 0.6rem;
    }
}

@media only screen and (min-width: 1024px) {
    .features .contentWidth {
        width: 85%;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .features .contentWidth {
        width: 75%;
    }
}

@media screen and (min-width: $breakpoint-lg) {
    .features .contentWidth {
        width: 58%;
    }
}

@media only screen and (max-width: $breakpoint-sm) {
    .features {
        .contentWidth {
            width: 100%;
        }

        .feature {
            font-size: 1.5rem;
        }

        .headerIcon {
            width: 28px;
        }
    }
}

@media screen and (max-width: $breakpoint-xs) {
    .features {
        padding: 2rem 0;

        .contentWidth {
            gap: .5rem;
            line-height: normal;
        }
    }
}

@media screen and (max-width:479px) {
    .features {
        padding: 1.5rem 1rem;
        font-size: 1.5rem;
    }
}
</style>
