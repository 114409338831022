import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import Meta from 'quasar/src/plugins/Meta.js';;
import VueAxios from 'vue-axios';
import mitt from 'mitt';
import VueGtag from 'vue-gtag';
import axios from '@/middleware/axiosMiddleware';
import App from './App.vue';
import router from './router';
import store from './store/store';
import metaMixin from '@/mixins/metaTags';
import quasarUserOptions from './quasar-user-options';
import customValidations from './assets/js/validation';
import commonFunctions, {
    preventNullInQSelect, formCompleteURL, getStoredCurrencyList, getCurrencySymbol, awsPath, getCurrentUserId, defaultImage,
} from './assets/js/commonFunctions';

const emitter = mitt();

if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '';
} else {
    axios.defaults.baseURL = process.env.VUE_APP_PROXY_URL;
}

const app = createApp(App).use(Quasar, quasarUserOptions).use(router).use(store)
    .use(VueAxios, axios)
    .use((Quasar, {
        plugins: {
            Meta,
        },
    }));

if (process.env.NODE_ENV === 'production') {
    app.use(VueGtag, {
        config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID },
    });
}

app.mixin(metaMixin);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.customValidations = customValidations;
app.config.globalProperties.setImage = commonFunctions;
app.config.globalProperties.preventNullInQSelect = preventNullInQSelect;
app.config.globalProperties.formCompleteURL = formCompleteURL;
app.config.globalProperties.storedCurrencyList = getStoredCurrencyList;
app.config.globalProperties.getCurrencySymbol = getCurrencySymbol;
app.config.globalProperties.awsPath = awsPath;
app.config.globalProperties.currentUserId = getCurrentUserId;
app.config.globalProperties.defaultImage = defaultImage;
app.mount('#app');
